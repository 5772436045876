import { graphql, useStaticQuery } from "gatsby";
import React, { FC } from "react";
import RellaxWrapper from "react-rellax-wrapper";
import BlockWrapper from "../features/BlockWrapper";
import GridItemsBlock from "../features/content-blocks/GridItemsBlock";
import TextGrid, { TextGridWrapperAbsolute } from "../features/TextGrid";
import { useResponsive } from "../hooks/useResponsive";
import Claim from "../partials/Claim";
import Menu from "../partials/Menu";
import { rem } from "../styling/theme";
import Background from "../ui/Cover";
import PageWrapper from "../ui/PageWrapper";
import { getCoverUrl } from "../utils/helpers";
import { ConceptsT, DataT } from "../utils/types";

const Concepts: FC = () => {
  const { strapiArt } = useStaticQuery(query);
  const conceptsData: DataT<ConceptsT> = strapiArt;

  const { tabletVersion, mobileVersion } = useResponsive();

  const { cover, intro, items, height, background_color } =
    conceptsData.data.attributes;

  return (
    <PageWrapper>
      <>
        <Menu isHomePage={false} />
        <Claim />
        <BlockWrapper
          cover={
            <>
              <Background
                url={getCoverUrl(
                  tabletVersion || mobileVersion,
                  cover.desktop,
                  cover.mobile
                )}
              />
              {intro && (
                <TextGridWrapperAbsolute>
                  <RellaxWrapper speed={-7}>
                    <TextGrid items={[intro]} />
                  </RellaxWrapper>
                </TextGridWrapperAbsolute>
              )}
            </>
          }
        />
        {items && (
          <GridItemsBlock
            items={items}
            fullHeight={false}
            customMinHeight={rem(height)}
            backgroundColor={background_color}
            lightBoxOption={true}
          />
        )}
      </>
    </PageWrapper>
  );
};

export default Concepts;

const query = graphql`
  query {
    strapiArt {
      data {
        attributes {
          items {
            id
            description
            x_cor
            y_cor
            z_cor
            media {
              data {
                attributes {
                  url
                }
              }
            }
          }
          background_color
          height
          cover {
            desktop {
              data {
                attributes {
                  url
                }
              }
            }
            mobile {
              data {
                attributes {
                  url
                }
              }
            }
          }
          intro {
            highlight_color
            id
            paragraph_size
            text
            text_color
            width
            x_offset
            y_offset
          }
        }
      }
    }
  }
`;
